exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-crm-tsx": () => import("./../../../src/pages/crm.tsx" /* webpackChunkName: "component---src-pages-crm-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-release-notes-index-tsx": () => import("./../../../src/pages/release-notes/index.tsx" /* webpackChunkName: "component---src-pages-release-notes-index-tsx" */),
  "component---src-pages-select-app-tsx": () => import("./../../../src/pages/select-app.tsx" /* webpackChunkName: "component---src-pages-select-app-tsx" */),
  "component---src-pages-tracker-tsx": () => import("./../../../src/pages/tracker.tsx" /* webpackChunkName: "component---src-pages-tracker-tsx" */),
  "component---src-pages-updates-index-tsx": () => import("./../../../src/pages/updates/index.tsx" /* webpackChunkName: "component---src-pages-updates-index-tsx" */),
  "component---src-templates-docs-template-tsx": () => import("./../../../src/templates/docs-template.tsx" /* webpackChunkName: "component---src-templates-docs-template-tsx" */),
  "component---src-templates-release-note-template-tsx": () => import("./../../../src/templates/release-note-template.tsx" /* webpackChunkName: "component---src-templates-release-note-template-tsx" */),
  "component---src-templates-updates-post-template-tsx": () => import("./../../../src/templates/updates-post-template.tsx" /* webpackChunkName: "component---src-templates-updates-post-template-tsx" */)
}

